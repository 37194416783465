<template>
  <page-wrapper :loading="loading" :no-access="!access">
    <v-toolbar
        flat
    >
      <v-toolbar-title>{{ pageName }}</v-toolbar-title>

      <v-spacer></v-spacer>

      <template v-slot:extension>
        <v-tabs
            v-model="tab"
            align-with-title
        >
          <v-tabs-slider  color="grey"></v-tabs-slider>

          <v-tab
              v-for="(item, i) in attrsMeta"
              :key="i"
          >
            {{ item.name }}
          </v-tab>
        </v-tabs>
      </template>
    </v-toolbar>
    <div class="save-button">
      <v-btn
        color="primary"
        elevation="2"
        small
        @click="createDocument()"
      >Сохранить</v-btn>
    </div>
    <v-tabs-items v-model="tab" style="max-width: 1000px">
      <v-tab-item
        v-for="(item, i) in attrsMeta"
        :key="i"
      >
        <v-card flat>
          <v-card-text>
            <v-form
                ref="form"
                v-model="valid"
                lazy-validation
            >
              <attribute-to-input
                  v-for="att in item.attributes"
                  :key="att.code"
                  :meta="att"
                  :select-values="enums[att.enumOrReferenceID]"
                  v-on:input="attValueChange(att, $event)"
              />
              <div  v-if="item.children">
                <div v-for="(ch, i)  in item.children" :key="i">
                  <h2 style="text-align: left" class="mb-4">{{ch.name}}</h2>
                  <attribute-to-input
                      v-for="att in ch.attributes"
                      :key="att.code"
                      :meta="att"
                      :select-values="enums[att.enumOrReferenceID]"
                      v-on:input="attValueChange(att, $event)"
                  />
                </div>
              </div>
            </v-form>
          </v-card-text>
        </v-card>
      </v-tab-item>
    </v-tabs-items>
    <div class="save-button">
      <v-btn
        color="primary"
        elevation="2"
        small
        @click="createDocument()"
      >Сохранить</v-btn>
    </div>
  </page-wrapper>
</template>

<script>
import PageWrapper from "../components/PageWrapper";
import { documentCreateMeta, documentCreate } from '../api/document';
import attributeToInput from "../components/slectors/AttributeToInput";

export default {
  name: "DocumentCreate",
  components: {
    PageWrapper,
    attributeToInput
  },
  data: function () {
    return {
      loading: true,
      access: true,
      pageName: '',
      attrsMeta: [],
      enums: {},
      tab: null,
      valid: true,
      docAttrs: {},
    }
  },
  watch: {},
  methods: {
    init() {
      this.loading = true
      documentCreateMeta(this.$route.params.typeID, this.$store.getters.getAuthToken)
        .then(v => {
          if (v.success) {
            this.pageName = v.body.name
            this.attrsMeta = v.body.attributesMeta
            this.enums = v.body.enums
          } else if (v.status === 403) {
            this.access = false
          } else {
            this.pageName = 'Внутренняя ошибка'
          }
        })
        .finally(() => {
          this.loading = false
        })
    },
    attValueChange(att, value) {
      this.docAttrs[att.code] = value
      console.log(this.docAttrs)
    },
    createDocument() {
      const typeID = parseInt( this.$route.params.typeID, 10)
      if (isNaN(typeID)) {
          console.info('Ошибка получения typeID')
          return
      }
      const doc = {
          "docTypeID": typeID,
          "docTypeCFT": "",
          "employeeID": 0,
          "employeeEmail": "",
          "parentID": 0,
          "attributes": this.docAttrs
      }

      this.loading = true
      documentCreate(doc, this.$store.getters.getAuthToken)
        .then(v => {
          if (v.success) {
            console.info('documentCreate success')
            // go to list
          } else if (v.status === 403) {
            this.access = false
          } else {
            this.pageName = 'Внутренняя ошибка'
          }
        })
        .finally(() => {
          this.loading = false
        })
    }
  },
  mounted() {
    this.init()
  }
}
</script>

<style scoped>
.save-button {
    padding: 16px;
}
</style>