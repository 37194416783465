<template>
  <v-dialog
      v-model="dialog"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
  >
    <template v-slot:activator="{ on }">
      <v-text-field
          :label="label"
          :value="selectedPersonName"
          v-on="on"
          readonly
          outlined
          dense
          @click="openDialog"
          append-icon="mdi-view-list-outline"
      >
      </v-text-field>
    </template>
    <v-card>
      <v-toolbar class="mb-2">
        <v-btn
            icon
            @click="dialog = false"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title>{{ label }}</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-btn
              text
              @click="readyBtnClick"
          >
            Готово
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>
      <v-text-field
          placeholder="Ничего не выбрано"
          :value="currentPersonName"
          readonly
          outlined
          filled
          class="ma-3"
          append-outer-icon="mdi-human-greeting-variant"
          @click:append-outer="selectCurrent"
      >
      </v-text-field>
      <v-divider></v-divider>
      <v-data-table
          v-model="selected"
          :loading="personalLoading"
          :headers="personalHeaders"
          :items="personalList"
          :search="search"
          item-key="id"
          single-select
          @click:row="clickRow"
          @dblclick:row="doubleClickRow"
      >
        <template v-slot:top>
          <v-text-field
              v-model="search"
              placeholder="Поиск"
              class="mx-3 mt-3"
              outlined
              dense
          ></v-text-field>
        </template>
      </v-data-table>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "ReferenceSelector",
  props: {
    label: {
      type: [String],
      default: null
    },
    value: {
      type: [Number, String],
      default: null,
    }
  },
  data () {
    return {
      dialog: null,
      search: '',
    }
  },
  computed: {
  },
  methods: {
    openDialog() {

    },
    readyBtnClick() {
      this.dialog = false
      this.search = ''
      this.$emit('input', this.selectedPerson.id)
    },
  },
}
</script>

<style scoped>

</style>