<template>
  <div>
    <v-text-field
        v-if="meta.type === 'S'"
        v-model="state"
        :counter="countLetters"
        :rules="rules"
        :label="meta.name"
        @input="$emit('input', $event)"
        outlined
        dense
    ></v-text-field>
    <v-text-field
        v-else-if="meta.type === 'EML'"
        v-model="state"
        :rules="rules"
        :label="meta.name"
        @input="$emit('input', $event)"
        outlined
        dense
    ></v-text-field>
    <v-textarea
        v-else-if="meta.type === 'M'"
        v-model="state"
        :counter="countLetters"
        :rules="rules"
        :label="meta.name"
        @input="$emit('input', $event)"
        outlined
    ></v-textarea>
    <v-select
        v-else-if="meta.type === 'Y' && selectValues"
        :items="selectValues"
        :label="meta.name"
        outlined
        dense
        clearable
        @input="$emit('input', $event)"
    ></v-select>
    <datetime-picker
        v-else-if="meta.type === 'DTME'"
        :label="meta.name"
        :text-field-props="{outlined: true, dense: true, 'append-icon': 'mdi-calendar-month'}"
        :date-picker-props="{'no-title': true, scrollable: true}"
        :time-picker-props="{'header-color': '#e0e0e0'}"
        @input="$emit('input', $event)"
    ></datetime-picker>
    <datetime-picker
        v-else-if="meta.type === 'D'"
        :label="meta.name"
        date-only
        :text-field-props="{outlined: true, dense: true, 'append-icon': 'mdi-calendar-month'}"
        :date-picker-props="{'no-title': true, scrollable: true}"
        @input="$emit('input', $event)"
    ></datetime-picker>
    <PersonalSelector
        v-else-if="meta.type === 'P'"
        :label="meta.name"
        v-model="state"
        @input="$emit('input', $event)"
    ></PersonalSelector>
    <ReferenceSelector
        v-else-if="meta.type === 'X'"
        :label="meta.name"
        v-model="state"
        @input="$emit('input', $event)"
    ></ReferenceSelector>
    <div v-else><strong>{{meta.name}}:{{meta.type}}</strong></div>
  </div>
</template>

<script>
import datetimePicker from "./DatetimePicker";
import PersonalSelector from "./PersonalSelector";
import ReferenceSelector from "./ReferenceSelector";


export default {
  name: "AttributeToInput",
  components: {ReferenceSelector, datetimePicker, PersonalSelector},
  props: ['value', 'meta', 'selectValues'],
  data() {
    return {
      state: '',
    }
  },
  computed: {
    rules() {
      switch (this.meta.type) {
        case 'S':
          return [v => [...v].length < 500 || 'Слишком длинный текст']
        case 'M':
          return [v => [...v].length < 2048 || 'Слишком длинный текст']
        case 'EML':
          return [v => [...v].length === 0 || /.+@.+\..+/.test(v) || 'Не верный формат']
        default:
          return []
      }
    },
    countLetters() {
      if (this.meta.type === 'S' && [...this.state].length > 400) {
        return 500
      }
      if (this.meta.type === 'M' && [...this.state].length > 1500) {
        return 2000
      }
      return null
    }
  },
  methods: {
    test(e) {
      console.log("te", e)
    }
  },
}
</script>

<style scoped>
</style>