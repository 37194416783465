<template>
  <v-dialog v-model="display" :width="dialogWidth">
    <template v-slot:activator="{ on }">
      <v-text-field
          v-bind="textFieldProps"
          :disabled="disabled"
          :label="label"
          :value="viewDateTime"
          v-on="on"
          readonly
      >
      </v-text-field>
    </template>

    <v-card>
      <v-card-text class="px-0 py-0">
        <div v-if="dateOnly">
          <v-date-picker
              v-model="date"
              v-bind="datePickerProps"
              @input="showTimePicker"
              locale="ru"
              full-width></v-date-picker>
        </div>
        <div v-else>
          <v-tabs fixed-tabs v-model="activeTab">
            <v-tab key="calendar">
              <slot name="dateIcon">
                <v-icon>mdi-calendar-month</v-icon>
              </slot>
            </v-tab>
            <v-tab key="timer" :disabled="dateSelected">
              <slot name="timeIcon">
                <v-icon>mdi-clock</v-icon>
              </slot>
            </v-tab>
            <v-tab-item key="calendar">
              <v-date-picker
                  v-model="date"
                  v-bind="datePickerProps"
                  @input="showTimePicker"
                  locale="ru"
                  full-width></v-date-picker>
            </v-tab-item>
            <v-tab-item key="timer">

              <v-time-picker
                  ref="timer"
                  class="v-time-picker-custom"
                  v-model="time"
                  v-bind="timePickerProps"
                  full-width
                  format="24hr"
              ></v-time-picker>
            </v-tab-item>
          </v-tabs>
        </div>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <slot name="actions" :parent="this">
          <v-btn color="red lighten-1" text @click.native="clearHandler">Отмена</v-btn>
          <v-btn color="green darken-1" text @click="okHandler">OK</v-btn>
        </slot>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import moment from 'moment-timezone';
const DEFAULT_DATE = ''
const DEFAULT_TIME = '00:00:00'
const DEFAULT_VIEW_FORMAT = 'DD-MM-YYYY HH:mm'
const DEFAULT_VIEW_DAYONLY_FORMAT = 'DD-MM-YYYY'
const DEFAULT_VIEW_OUT_FORMAT = 'DD-MM-YYYY HH:mm:ss'
const DEFAULT_DIALOG_WIDTH = 340

export default {
  name: 'v-datetime-picker',
  components: {
  },
  model: {
    prop: 'datetime',
    event: 'input'
  },
  props: {
    datetime: {
      type: [String],
      default: null
    },
    datetimeFormat: {
      type: String,
      default: null,
    },
    datetimeOutFormat: {
      type: String,
      default: null,
    },
    dateOnly: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean
    },
    label: {
      type: String,
      default: ''
    },
    dialogWidth: {
      type: Number,
      default: DEFAULT_DIALOG_WIDTH
    },
    viewFormat: {
      type: String,
      default: null,
    },
    textFieldProps: {
      type: Object
    },
    datePickerProps: {
      type: Object
    },
    timePickerProps: {
      type: Object
    }
  },
  data() {
    return {
      display: false,
      activeTab: 0,
      date: DEFAULT_DATE,
      time: DEFAULT_TIME
    }
  },
  mounted() {
    this.init()
  },
  computed: {
    viewDateTime() {
      let format = this.viewFormat
      if (!this.viewFormat) {
        format = DEFAULT_VIEW_FORMAT
      }
      if (this.dateOnly && !this.viewFormat) {
        format = DEFAULT_VIEW_DAYONLY_FORMAT
      }
      return this.selectedDatetime ? this.selectedDatetime.format(format) : ''
    },
    outputDatetime() {
      let format = this.datetimeOutFormat
      if (!this.datetimeOutFormat) {
        format = DEFAULT_VIEW_OUT_FORMAT
      }
      return this.selectedDatetime ? this.selectedDatetime.format(format) : ''
    },
    selectedDatetime() {
      if (this.dateOnly && this.date) {
        return moment(this.date, 'YYYY-M-D')
      }
      if (this.date && this.time) {
        let datetimeString = this.date + ' ' + this.time
        return moment(datetimeString, 'YYYY-M-D H:m')
      }
      return null
    },
    dateSelected() {
      return !this.date
    }
  },
  methods: {
    init() {
      if (!this.datetime) {
        return
      }
      let initDateTime
      if (this.datetimeFormat) {
        initDateTime = moment(this.datetime, this.datetimeFormat)
      } else {
        initDateTime = moment(this.datetime)
      }
      this.date = initDateTime.format('YYYY-M-D')
      this.time = initDateTime.format('H:m:s')
    },
    okHandler() {
      this.resetPicker()
      this.$emit('input', this.outputDatetime)
    },
    clearHandler() {
      this.resetPicker()
      this.date = DEFAULT_DATE
      this.time = DEFAULT_TIME
      this.$emit('input', null)
    },
    resetPicker() {
      this.display = false
      this.activeTab = 0
      if (this.$refs.timer) {
        this.$refs.timer.selectingHour = true
      }
    },
    showTimePicker() {
      this.activeTab = 1
    }
  },
  watch: {
    datetime: function() {
      this.init()
    }
  }
}
</script>

<style>
.v-time-picker-custom {
  /*height: 92px;*/
  padding-top: 10px;
}
</style>