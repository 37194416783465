<template>
  <v-dialog
      v-model="dialog"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
  >
    <template v-slot:activator="{ on }">
      <v-text-field
          :label="label"
          :value="selectedPersonName"
          v-on="on"
          readonly
          outlined
          dense
          @click="openDialog"
          append-icon="mdi-human-male"
      >
      </v-text-field>
    </template>
    <v-card :loading="personalLoading">
      <v-toolbar class="mb-2">
        <v-btn
            icon
            @click="dialog = false"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title>Выбор сотрудника</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-btn
              text
              @click="readyBtnClick"
          >
            Готово
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>
      <v-text-field
          placeholder="Ничего не выбрано"
          :value="currentPersonName"
          readonly
          outlined
          filled
          class="ma-3"
          append-outer-icon="mdi-human-greeting-variant"
          @click:append-outer="selectCurrent"
      >
      </v-text-field>
      <v-divider></v-divider>
      <v-data-table
          v-model="selected"
          :loading="personalLoading"
          :headers="personalHeaders"
          :items="personalList"
          :search="search"
          item-key="id"
          single-select
          @click:row="clickRow"
          @dblclick:row="doubleClickRow"
      >
        <template v-slot:top>
          <v-text-field
              v-model="search"
              placeholder="Поиск"
              class="mx-3 mt-3"
              outlined
              dense
          ></v-text-field>
        </template>
      </v-data-table>
    </v-card>
  </v-dialog>
</template>

<script>
import {mapState} from "vuex";

export default {
  name: "PersonalSelector",
  props: {
    label: {
      type: [String],
      default: null
    },
    value: {
      type: [Number, String],
      default: null,
    }
  },
  data () {
    return {
      dialog: false,
      search: '',
      selectedPerson: null,
      selected: [],
      personalHeaders: [
        {text: 'Фамилия', value: 'lastName'},
        {text: 'Имя', value: 'firstName'},
        {text: 'Отчество', value: 'secName'},
        {text: 'Должность', value: 'stuff'},
        {text: 'Офис', value: 'office'},
        {text: 'Подразделение', value: 'department'},
        {text: 'Email', value: 'email'},
        {text: 'Городской телефон', value: 'phone'},
      ],
    }
  },
  watch: {
  },
  computed: {
    ...mapState('personal', ['personalList', 'personalLoading']),
    valueAsNumber() {
      return Number(this.value)
    },
    currentPersonName() {
      return this.currentPerson ? this.currentPerson.lastName + ' ' + this.currentPerson.firstName : ''
    },
    currentPerson() {
      return this.selected[0] ? this.personalList.find(el => el.id === this.selected[0]) : null
    },
    selectedPersonName() {
      let person = this.personalList.find(el => el.id === this.valueAsNumber)

      if (!person) {
        return ''
      }
      return person.lastName + ' ' + person.firstName
    },
  },
  methods: {
    openDialog() {
      if (this.personalList.length === 0 && !this.personalLoading) {
        this.$store.dispatch('personal/fetchAllPersonal')
      }
    },
    clickRow(el) {
      this.selected = [el.id]
    },
    doubleClickRow(event, {item}) {
      this.clickRow(item)
      this.readyBtnClick()
    },
    readyBtnClick() {
      this.dialog = false
      this.selectedPerson = this.currentPerson
      this.search = ''
      this.$emit('input', this.selectedPerson.id)
    },
    selectCurrent() {
      console.log(this.$store.getters.getUser.personalEqmID)
      this.selected = [this.$store.getters.getUser.personalEqmID]
    },
  },
  mounted() {
    if (this.value && this.personalList.length === 0 && !this.personalLoading) {
      this.$store.dispatch('personal/fetchAllPersonal')
    }
  }
}
</script>

<style scoped>

</style>